const theme = {
  initialColorModeName: "default",
  initialColorMode: "dark",
  useColorSchemeMediaQuery: true,
  useLocalStorage: false,
  breakpoints: ["40rem", "52rem", "64rem", "120rem", "140rem"],
  colors: {
    text: "#e3e3e3",
    textQuiet:"#888888",
    heading: "#cccccc",
    bigTitle: "#c6c6c6",
    bigTitleQuiet: "#5D5D5D",
    background: "#222222",
    backgroundElevation:"#292929",
    backgroundElevationOpacity: "rgba(255,255,255,0.04)",
    overlay:"rgba(34,34,34,0.9)",
    // primary: "#f0c844",
    primaryInteractive: "#FFF",
    borderColor: "rgba(255,255,255,0.4)",
    borderColorQuiet: "rgba(255,255,255,0.08)",
    inverted: "#fff",
    bgImg: "#cccccc",
    coverBook:"#f0c844",
    boseProjectBgDs: "#747546",
    boseProjectTextDs:"#E6FE52",
    boseProjectBgImmersive: "#6C457E",
    boseProjectTextImmersive:"#DFDEFA",
    accellProjectBgDs:"#dbe2d4",
    accellProjectTextDs:"#6d6336",
    accellProjectBgECom:"#ecd461",
    accellProjectTextECom:"#235967",
    accellProjectBgLP:"#24137b",
    accellProjectTextLP :"#e2ebdb",
    oberonProjectBgTravelbase:"#36558C",
    oberonProjectTextTravelbase: "#FFE0BB",
    oberonProjectBgEasybroker: "#623acc",
    oberonProjectTextEasybroker: "#FFE5DD",
    modes: {
      dark: {
        text: "#e3e3e3",
        textQuiet:"#888888",
        heading: "#cccccc",
        bigTitle: "#c6c6c6",
        bigTitleQuiet: "#5D5D5D",
        background: "#222222",
        backgroundElevation:"#292929",
        backgroundElevationOpacity: "rgba(255,255,255,0.04)",
        overlay:"rgba(34,34,34,0.9)",
        // primary: "#f0c844",
        primaryInteractive: "#FFF",
        borderColor: "rgba(255,255,255,0.4)",
        borderColorQuiet: "rgba(255,255,255,0.08)",
        inverted: "#fff",
        bgImg: "#cccccc",
        coverBook:"#f0c844",
      },
      light: {
        text: "#3d3d3d",
        textQuiet:"#888888",
        heading: "#3d3d3d",
        bigTitle: "#3d3d3d",
        bigTitleQuiet: "#B3B3B3",
        background: "#fff",
        backgroundElevation:"#f3f3f3",
        backgroundElevationOpacity: "rgba(255,255,255,0.2)",
        // primary: "#3d3d3d",
        primaryInteractive: "#121212",
        borderColor: "rgba(0,0,0,0.3)",
        borderColorQuiet: "rgba(0,0,0,0.08)",
        inverted: "#fff",
        bgImg: "#f3f3f3",
        coverBook:"#f0c844",
      },
      pink: {
        text: "#373306",
        heading: "#373306",
        bigTitle:"#554e00",
        bigTitleQuiet: "#BAAD8E",
        background: "#FEEDED",
        backgroundElevation:"#F9E1E1",
        backgroundElevationOpacity: "rgba(249,200,190,0.2)",
        // primary: "#373306",
        primaryInteractive: "#373306",
        borderColor: "rgba(0,0,0,0.15)",
        borderColorQuiet: "rgba(0,0,0,0.08)",
        inverted: "#fff",
        bgImg: "#FEEDED",
        coverBook:"#F9C8BE",
      },
    },
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  radii: {
    default: 99,
  },
  fonts: {
    body: "Graphik Web, system-ui, sans-serif",
    heading: "inherit",
    display: "FK Screamer, system-ui, sans-serif",
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 600,
    medium: 500,
  },
  lineHeights: {
    body: 1.75,
    heading: 1,
    display: 0.875,
  },
  fontSizes: [12, 14, 16, 18, 20, 24, 28, 32, 36, 44, 48],
  buttons: {
    primary: {
      color: "background",
      bg: "bigTitle",
      fontFamily: "body",
      fontWeight: "bold",
      fontSize: "inherit",
      borderRadius: "default",
      lineHeight: "body",
      px: 4,
      py: 2,
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
      "& > svg": {
        transform: "rotate(45deg)",
        transformOrigin: "50% 50%",
        width: ["2rem"],
        height: ["2rem"],
        mt:-1,
        mb: -1,
        ml:3,
        mr: "-2.75rem",
        p: [2,"1rem"],
        backgroundColor: "background",
        color: "text",
        borderRadius: "default",
      },
      "&:hover": {
        backgroundColor: "primaryInteractive",
      },
    },
    icon: {
      m: 0,
      px: 0,
      py: 0,
      overflow: "hidden",
      paddingBlockStart: "0",
      paddingBlockEnd: "0",
      paddingInlineStart: "0",
      background: "transparent",
      float: "right",
      "& > svg": {   
        transform: "rotate(45deg)",
        transformOrigin: "50% 50%",
        borderRadius: "default",
        backgroundColor: "background",
        color: "text",
        width: ["2rem"],
        height: ["2rem"],
        mt:0,
        mb: 0,
        ml:0,
        mr: 0,
        p: [2,3],
      },
      "&:hover > svg": {
        backgroundColor: "primaryInteractive",
        color: "background",
      },
    },
    outline: {
      color: "text",
      bg: "backgroundElevation",
      fontFamily: "body",
      fontWeight: "medium",
      //fontSize: [1,1,2],
      borderRadius: "default",
      // borderWidth: "1px",
      // borderColor: "borderColor",
      // borderStyle: "solid",
      lineHeight: "body",
      px: [3,4],
      py: [1,2],
      my: [1],
      display: "inline-flex",
      alignItems: "center",
      transitionProperty: "background-color",
      transitionTimingFunction: "ease-out",
      transitionDuration: "0.2s",
      "& > svg": {
        transform: "rotate(-180deg)",
        transformOrigin: "50% 50%",
        width: ["2rem"],
        height: ["2rem"],
        p: [2,"1rem"],
        ml: "-1rem",
        color: "inherit",
      },
      "&:hover": {
        color: "background",
        backgroundColor: "text",
      },
      "&.active": {
        backgroundColor: "text",
        color: "background",
      }
    },
    themeDot: {
      borderRadius: "default",
      bg: "background",
      width: 32,
      height: 32,
      borderWidth: "1px",
      borderColor: "borderColor",
      borderStyle: "solid",
      mx: [3, 4],
      padding: "0",
      color: "text",
    },
  },
  text: {
    heading: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
      color: "heading",
      mb: [2, 3],
    },
    bigTitle: {
      fontFamily: "display",
      fontWeight: "heading",
      fontSize: ["calc(0.75rem + 14vw)", "calc(0.75rem + 14vw)", "10.5vw"],
      color: "bigTitle",
      lineHeight: "display",
      letterSpacing: "[0, 0.25]",
      mt: 3,
      display: "block",
      width: "100%",
      "& > span": {
        display: "inline-block",
        position: "relative",
      },
      "& > span.outline": {
        // WebkitTextFillColor: "transparent",
        // WebkitTextStrokeWidth: ["1px", "1px","1px", "2px"],
        // WebkitTextStrokeColor: "inherit",
        color: "bigTitleQuiet"
      },
      "& > sup": {
        fontSize: "50%",
        textTransform: "none!important",
        ml: ["-1vw"],
      },
    },
    bigTitleS: {
      fontFamily: "display",
      fontWeight: "heading",
      color: "bigTitle",
      fontSize: ["14vw", "10vw","8vw"],
      //whiteSpace: "nowrap",
      lineHeight: "0.875",
      wordSpacing: "0.1em"
    },
    bigTitleXS: {
      fontFamily: "body",
      color: "heading",
      letterSpacing:"-0.012em",
      fontSize: [5,9],
      lineHeight: "1.25",
      pb: [2, 3],
    },
    bigTitleXXS: {
      fontFamily: "display",
      fontWeight: "heading",
      color: "text",
      textTransform: "uppercase",
      fontSize: ["calc(1rem + 8vw)","calc(1rem + 5vw)","calc(1rem + 6vw)","calc(1rem + 2.25vw)"],
      lineHeight: "0.875",
      wordSpacing: "0.1em",
      letterSpacing: "0.016em",
      position: "relative",
      "& > sup": {
        fontSize: [2],
        fontFamily: "body",
        fontWeight: "body",
        textTransform: "none!important",
        position: "absolute",
        top: [1],
        ml:[1],
      },
    },
    catHeading: {
      fontFamily: "body",
      fontSize: "calc(1.6rem + 0.25vw)",
      fontWeight: "medium",
      //textTransform: "uppercase",
      color:"textQuiet",
      //letterSpacing: "0.025em",
      borderWidth: "1px 0 0",
      borderStyle: "solid",
      borderColor: "borderColorQuiet",
      pt: [3, 4],
      mb: [3],
    },
    paragraph: {
      maxWidth: "75em",
      pb: [3, 4],
    },
    paragraphXL: {
      fontSize: ["calc(1.6rem + 1.25vw)", "calc(1.6rem + 1.25vw)", "calc(1.6rem + 0.65vw)"],
      lineHeight: "1.5",
      fontWeight:"body",
      "& > strong": {
        fontWeight: "bold",
      },
    },
    caption: {
      fontSize: [1,1,1,1,"calc(0.75rem + 0.25vw)"],
      lineHeight: 1.75,
      display: "block",
      py: [2,4],
      pt: [3],
      opacity: "0.85",
      textAlign: "left",
      maxWidth: "48rem",
    },
    quote: {
      fontSize: [4,5,6],
      fontWeight: "medium",
      lineHeight: "1.5",
      maxWidth: "56.4rem",
      float: "right",
      px:[1,2,3,4],
      py: [2]
    },
    projectLabel: {
        display: "inline-block",
        color: "background",
        bg: "bigTitle",
        position: "relative",
        fontFamily: "body",
        fontWeight: "bold",
        fontSize: ["1rem","1.15rem"],
        textTransform: "uppercase",
        lineHeight: "1.75",
        px:["1rem"], 
        py:[1],
        mb: [2],
        borderRadius: "default",
        "&::after": {
          content:'" "',
          position: "absolute",
          width: "0",
          height: "0",
          left: "16px",
          right: "auto",
          top: "auto",
          bottom: "-8px",
          borderWidth: "8px",
          borderStyle: "solid",
          borderTopColor: "transparent",
          borderRightColor: "transparent",
          borderBottomColor: "transparent",
          borderLeftColor: "bigTitle",      
        }
    },
  },
  links: {
    nav: {
      fontWeight: "heading",
      position: "relative",
      lineHeight: "body",
      borderRadius: "default",
      display: "inline-block",
      px: ["2.4rem",4],
      py: ["0.6rem"],
      //m:[1],
      transition: "all 0.2s ease-in-out",
      "&.active": {
        //color: "text",
        bg: "background"
      },
      "&:hover": {
        backgroundColor: "text",
        color: "backgroundElevation"
      }
    },
    imgLink: {
      display: "block",
      cursor: "pointer",
      borderWidth: "1px 0px 0px",
      borderStyle: "solid",
      borderColor: "borderColorQuiet",
      py:[3,4],
      "&.active": {
        position: "relative",
        color: "bigTitleQuiet",
        display: "none"
      },
      "&.active::after": {
        content:'" "',
        position: "absolute",
        width: "100%",
        maxWidth:["50%","25%"],
        height: ["40%","130%"],
        maxHeight: ["16rem","24rem"],
        borderRadius: "50%",
        top: ["0","2%"],
        left: "-3vw",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "text",
        transform:"rotate(5deg)",
        transition: "transform 0.2s ease-in-out"
      },
      "&.active:hover::after": {
        transform:"rotate(-1deg)",
        transition: "transform 0.2s ease-in-out"
      },
    },
  },
  styles: {
    // root: {
    //   transitionProperty: "background-color",
    //   transitionTimingFunction: "ease-in-out",
    //   transitionDuration: ".15s",
    // },
    p: {
      mb: [4,5,6],
      pr: [0, 4, 5],
    },
  },
  variants: {
    aLink: {
      position: "relative",
      lineHeight: "body",
      display: "inline-block",
      pb: 1/2,
      fontWeight: "medium",
      "&:after": {
        content: '" "',
        width: "100%",
        height: "2px",
        backgroundColor: "borderColor",
        position: "absolute",
        bottom: "0",
        left: "0",
      },
      "&:hover:after": {
        backgroundColor: "bigTitle",
      },
    },
    container: {
      width: "100vw",
      maxWidth: ["88vw","92vw"],
      mx: "auto",
      pt: [4,5],
      mb: [0,2,3,5,6]
    },
    containerHero: {
      width: "100vw",
      maxWidth: ["88vw","92vw"],
      mx: "auto",
      pb: [3, 4],
      my:[0],
    },
    containerHeader: {
      width: "100vw",
      maxWidth: ["88vw","92vw"],
      mx: "auto",
      my: [2,0],
      py: [2,3,4],
      left: ["6vw","4vw"],
      position: "absolute",
      zIndex: "9999",
    },
    containerPage: {
      maxWidth: ["88vw","92vw"],
      mx: "auto",
      mt: [5],
      pt:[5],
      mb: [0,0,0,6],
    },
    containerTxt: {
      width: "100%",
      maxWidth: ["56.4rem", "56.4rem", "76.4rem", "88.4rem"],
      mt: [3,4],
      mb: [4, 5],
    },
    containerRelatedProject: {
      width: "100%",
      position: "relative"
    },
    containerBigTitle: {
      mb: [3],
      pl: [0, 0, 4, 5, 6],
    },
    containerBigTitleImg: {
      borderWidth:["0 0 0 0","0 0 0 0", "0 0 0 1px"],
      borderStyle: "solid",
      borderColor: "borderColorQuiet",
      pl:[0,0,5],
      pr: [0, 0, 5, 5, 6],
    },
    containerSmallSreen: {
      maxWidth: "32rem"
    },
    containerCard: {
      maxWidth: "64rem",
      alignSelf: ["stretch"],
      flex: ["100%", "1"],
    },
    wrapperVideo: {
      position: "relative",
      pb: "51.56%",
      height: "0",
      overflow: "hidden",
      "& > video": {
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
      },
      "&:before": {
        width: "100%",
        height: "100%",
        position: "absolute",
        backgroundColor: "background"
      }
    },
    wrapperVideoIFrame: {
      position: "relative",
      pb: "51.56%",
      height: "0",
      overflow: "hidden",
      "& > iframe": {
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
      },
      "&:before": {
        width: "100%",
        height: "100%",
        position: "absolute",
        backgroundColor: "background"
      }
    },
    wrapperVideoPortrait: {
      position: "relative",
      pb: "208.21%",
      height: "0",
      overflow: "hidden",
      "& > video": {
        position: "absolute",
        top: "4px",
        left: "1px",
        width: "calc(100% + 2px)",
        height: "calc(100% + 8px)",
      },
      "&:before": {
        width: "100%",
        height: "100%",
        position: "absolute",
        backgroundColor: "background"
      }
    },
    projectCard: {
      m: 0,
      mb:[1,2,4],
      px: [0,2,4,5],
      transitionProperty: "opacity",
      transitionTimingFunction: "ease-out",
      transitionDuration: "0.1s",
      '@media screen and (min-width: 40em)': {
        borderWidth: "0 1px 0 0",
        borderStyle: "solid",
        borderColor: "borderColorQuiet",
      },
      "&:hover": {
        opacity: "0.75",
      },
      "&:last-of-type": {
        borderWidth: "0 0 0 0",
        pr:[0,0,0,0]
      },
      "&:first-of-type": {
        pl: [0,0,0],
        px: [0,2,4,5],
        borderWidth: "0 1px 0 1px",
      },
    },
    quote: {
      fontSize: "calc(100% + 0.75vw)",
      lineHeight: "1.25",
      mt: [1, 2, 3],
      mb: [2, 3, 4],
      letterSpacing: "normal",
    },
    wrapperImg: {
      position: "relative",
      width: "100%",
      height: "auto",
      textAlign: "center",
      "& img": {
        mixBlendMode: "multiply",
      },
      "&:before": {
        content: '" "',
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        backgroundColor: "bgImg",
      },
    },
    wrapperImgBorder: {
      position: "relative",
      width: "100%",
      height: "auto",
      textAlign: "center",
      "& img": {
        mixBlendMode: "multiply",
        borderWidth: "1px", 
        borderStyle: "solid", 
        borderColor: "borderColorQuiet"
      },
      "&:before": {
        content: '" "',
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        backgroundColor: "bgImg",
      },
    },
    pageTransition: {
      color: "bgImg",
    },
    chatBubble: {
      borderRadius: "2rem",
      backgroundColor:"text",
      position:"relative",
      color:"background",
      p: [3, 4],
      "&::after": {
        content:'" "',
        position: "absolute",
        width: "0",
        height: "0",
        right: "2.5rem",
        left: "auto",
        top: "auto",
        bottom: "-1.75rem",
        borderWidth: "2rem",
        borderStyle: "solid",
        borderTopColor: "text",
        borderRightColor: "transparent",
        borderBottomColor: "transparent",
        borderLeftColor: "text",        
      }
    },
    onPageNav: {
      width: ["100%", "auto", "auto"],
      backgroundColor: "backgroundElevationOpacity",
      borderRadius: ["default"],
      position: "fixed",
      bottom: [0, 2, 3],
      px:[2,1],
      py:[1,0],
      left: [0, "auto", "auto", "auto", "auto"],
      right: ["auto", "auto", "auto", "4vw", "4vw"],
      zIndex: "999",
      whiteSpace: "nowrap",
      overflowX: "auto",
      bg:["background","transparent"],
      backdropFilter: ["none","blur(24px)"],
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
  },
};

export default theme;
